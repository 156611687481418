import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

//Reducers
import Dashboard from './Dashboard';
import BatchInfo from './BatchInfo';
import CandidateList from './CandidateList';
import x0paDuser from './X0paDuser';
import AdminSection from './AdminSection';
import CandidateInfo from './CandidateInfo';
import HighLevelReports from './HighLevelReports';
import CalendarSelectionBoard from './CalendarSelectionBoard';
import CompareApplications from './CompareApplications';
import CandidateNotes from './CandidateNotes';
import AuditLogs from './AuditLogs';
import Assessments from './Assessments';
import FeedbackTemplates from './FeedbackTemplates';
import CMSMeetings from './CMSMeetings';
import Emails from './Emails';
import PscPeerFeedback from './PscPeerFeedback';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    Dashboard,
    BatchInfo,
    CandidateList,
    x0paDuser,
    AdminSection,
    CandidateInfo,
    HighLevelReports,
    CalendarSelectionBoard,
    CompareApplications,
    CandidateNotes,
    AuditLogs,
    Assessments,
    FeedbackTemplates,
    CMSMeetings,
    Emails,
    PscPeerFeedback
  });
