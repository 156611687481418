import { Select, SelectItem } from 'carbon-components-react';
import _ from 'lodash';

export const SelectionYearDropdown = ({
  currentYear,
  selectionYear,
  mainClassName,
  titleClassName,
  handleChange,
  showAllSelectionYears = false,
  hideLabel,
  isDefaultSelectDiabled = true,
  name = 'selectionYear',
}) => {
  return (
    <div className={mainClassName}>
      <Select
        name={name}
        id={name}
        onChange={handleChange}
        className={titleClassName}
        labelText="Selection Year"
        value={selectionYear}
        hideLabel={hideLabel}>
        <SelectItem
          text="Selection Year"
          key="Selection Year"
          value="Selection Year"
          disabled
          hidden
        />
        <SelectItem text="Select" disabled={isDefaultSelectDiabled} key={0} />
        {showAllSelectionYears ? (
          <>
            {_.range(2020, currentYear + 1).map((year, idx) => {
              return (
                <SelectItem
                  text={`Selection Year ${year}`}
                  key={year}
                  id={year}
                  value={year}
                />
              );
            })}
          </>
        ) : (
          <>
            {_.range(currentYear, currentYear + 2).map((year, idx) => {
              return (
                <SelectItem
                  text={`For ${year} selection year (for award by June ${year})`}
                  key={year}
                  id={year}
                  value={year}
                />
              );
            })}
          </>
        )}
      </Select>
    </div>
  );
};
