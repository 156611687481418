import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackRow from '../../../components/common/BackRow';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  Button,
  Pagination,
  FileUploaderButton,
  Tile,
  InlineNotification,
  Select,
  SelectItem,
  Modal,
  TextInput,
} from 'carbon-components-react';

import {
  getAssessments,
  addAssessment,
  updateAssessment,
} from '../../../actions/PscPeerFeedback';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SelectionYearDropdown } from '../../../components/common/SelectionYearDropdown';

const headers = [
  {
    key: 'header_0',
    header: 'Name',
  },
  {
    key: 'header_1',
    header: 'Selection Year',
  },
  {
    key: 'header_2',
    header: 'Status',
  },
  {
    key: 'header_3',
    header: 'Start Date',
  },
  {
    key: 'header_4',
    header: 'End date',
  },
  {
    key: 'header_5',
    header: 'Action',
  },
];

class AssessmentsList extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'psc-peer-feedback', name: 'Assessments', href: '' },
  ];

  constructor(props) {
    super(props);
    const { currentYear } = this.props.user;
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: '',
      countryId: 65,
      fromDate: moment().subtract(6, 'months').toDate(),
      toDate: moment().toDate(),
      startDate: moment().toDate(),
      endDate: moment().add(1, 'months').toDate(),
      isExporting: false,
      scenario: 'Scenario',
      selectionYear: currentYear,
      statusFilter: 'Status',
      selectionYearFilter: 'Selection Year',
      status: 'Draft',
    };
  }

  componentDidMount() {
    this.handleGetAssessments();
  }

  getPayload = () => {
    const {
      activePage,
      itemsPerPage,
      searchKey,
      fromDate,
      toDate,
      selectionYearFilter,
      statusFilter,
    } = this.state;
    return {
      activePage,
      itemsPerPage,
      searchKey,
      startDate: `"${moment(fromDate).format('YYYY-MM-DD')}"`,
      endDate: `"${moment(toDate).format('YYYY-MM-DD')}"`,
      status:
        !statusFilter || statusFilter == 'Status'
          ? null
          : statusFilter === 'All'
          ? ['Draft', 'In Progress', 'Completed']
          : [statusFilter],
      selectionYear:
        !selectionYearFilter || selectionYearFilter == 'Selection Year'
          ? null
          : selectionYearFilter,
    };
  };

  handleGetAssessments = () => {
    const data = this.getPayload();
    this.props.getAssessments(data);
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetAssessments();
    });
  };

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      {
        searchKey: event.target.value,
        isLoading: true,
        activePage: 1,
        itemsPerPage: 10,
      },
      () => {
        this.searchTimer = setTimeout(() => {
          this.handleGetAssessments();
        }, 1000);
      }
    );
  };

  openAddAssessModal = (data) => {
    const updateState = { isAddAssessModalOpen: true };
    if (data) {
      updateState.surveyId = data?.surveyId;
      updateState.assessmentName = data?.name;
      updateState.selectionYear = data?.selectionYear;
      updateState.startDate = data?.startDate;
      updateState.endDate = data?.endDate;
      updateState.status = data?.status;
    }

    this.setState({
      ...updateState,
    });
  };

  dismissAddAssessModal = () => {
    const { currentYear } = this.props.user;
    this.setState({
      isAddAssessModalOpen: false,
      surveyId: null,
      assessmentName: null,
      selectionYear: currentYear,
      startDate: moment().toDate(),
      endDate: moment().add(1, 'months').toDate(),
      status: 'Draft',
      isAssessNameInvalid: false,
      isSelectionYearInvalid: false,
      isDateRangeInvalid: false,
      isStatusInvalid: false,
    });
  };
  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleUploadCandidates = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('excel', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadAssessCandidatesData(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                this.handleGetAssessments();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date, isDateRangeInvalid: false });
  };

  handleSubmitDates = () => {
    this.setState(
      {
        isStartEndDatesInvalid: this.state.fromDate > this.state.toDate,
      },
      () => {
        if (!this.state.isStartEndDatesInvalid) {
          this.handleGetAssessments();
        }
      }
    );
  };

  handleTabClick = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  handleFilterDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleGetAssessments();
      }
    );
  };

  handleSubmitAddAssess = () => {
    const {
      assessmentName,
      selectionYear,
      startDate,
      endDate,
      surveyId,
      status,
    } = this.state;
    const invalidObj = {};
    invalidObj.isAssessNameInvalid = this.isInputInValid(assessmentName);
    invalidObj.isSelectionYearInvalid = selectionYear == null;
    invalidObj.isDateRangeInvalid =
      !startDate ||
      !endDate ||
      (startDate && endDate && startDate > endDate) ||
      false;
    invalidObj.isStatusInvalid = status == null;

    this.setState({ ...invalidObj }, () => {
      const areAllInputsValid = Object.values(invalidObj).every(
        (val) => val === false
      );
      if (areAllInputsValid) {
        const data = {
          name: assessmentName,
          selectionYear,
          startDate,
          endDate,
          status,
        };

        if (surveyId) {
          data.surveyId = surveyId;
        }
        if (surveyId) {
          this.props
            .updateAssessment(data)
            .then((res) => {
              const { message, error } = res || {};
              this.dismissAddAssessModal();
              if (res && message && !error) {
                this.setState(
                  {
                    successNotif: true,
                    notifMsg: message,
                    isSubmitting: false,
                  },
                  () => {
                    this.handleGetAssessments();
                    setTimeout(() => {
                      this.setState({
                        notifMsg: '',
                        successNotif: false,
                      });
                    }, 5000);
                  }
                );
              } else {
                this.setState({
                  errorNotif: true,
                  errMsg: error?.message,
                  isSubmitting: false,
                });
              }
            })
            .catch((e) => {
              this.dismissAddAssessModal();
              this.setState({
                errorNotif: true,
                isSubmitting: false,
              });
            });
        } else {
          this.props
            .addAssessment(data)
            .then((res) => {
              const { pscPeerFeedbackSurvey, message, error } = res || {};
              this.dismissAddAssessModal();
              if (res && pscPeerFeedbackSurvey && !error) {
                this.setState(
                  {
                    successNotif: true,
                    notifMsg: message,
                    isSubmitting: false,
                  },
                  () => {
                    this.handleGetAssessments();
                    setTimeout(() => {
                      this.setState({
                        notifMsg: '',
                        successNotif: false,
                      });
                    }, 5000);
                  }
                );
              } else {
                this.setState({
                  errorNotif: true,
                  errMsg: error?.message,
                  isSubmitting: false,
                });
              }
            })
            .catch((e) => {
              this.dismissAddAssessModal();
              this.setState({
                errorNotif: true,
                isSubmitting: false,
              });
            });
        }
      }
    });
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { assessments, assessmentsCount, user } = this.props;
    const {
      successNotif,
      errorNotif,
      notifMsg,
      activePage,
      isAddAssessModalOpen,
      errMsg,
      selectedTab,
      isSubmitting,
      startDate,
      endDate,
      isStartEndDatesInvalid,
      isExporting,
      scenario,
      selectionYearFilter,
      selectionYear,
      statusFilter,
      status,
      surveyId,
      assessmentName,
      isAssessNameInvalid,
      isSelectionYearInvalid,
      isStatusInvalid,
      isDateRangeInvalid,
      fromDate,
      toDate,
    } = this.state;

    const { currentYear } = user || {};
    const otherActionButtons = [
      <div className="ml-3 mr-3">
        <SelectionYearDropdown
          currentYear={currentYear}
          selectionYear={selectionYearFilter}
          handleChange={this.handleFilterDropDown}
          mainClassName="w-100"
          titleClassName="xpa-width"
          hideLabel
          showAllSelectionYears
          isDefaultSelectDiabled={false}
          name="selectionYearFilter"
        />
      </div>,
      <Select
        placeHolderText="Status"
        name="statusFilter"
        id="statusFilter"
        onChange={this.handleFilterDropDown}
        value={statusFilter}
        hideLabel
        className="w-100 mr-3">
        <SelectItem text="Status" key="Status" value="Status" disabled hidden />
        <SelectItem text="Select" disabled />
        <SelectItem text="Draft" key="Draft" id="Draft" value="Draft" />
        <SelectItem
          text="In Progress"
          key="In Progress"
          id="In Progress"
          value="In Progress"
        />
        <SelectItem
          text="Completed"
          key="Completed"
          id="Completed"
          value="Completed"
        />
        <SelectItem text="All" key="All" id="All" value="All" />
      </Select>,
      <Button
        className=""
        kind="primary"
        onClick={() => this.openAddAssessModal()}>
        Add Assessment
      </Button>,
    ];

    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        {isStartEndDatesInvalid && (
          <InlineNotification
            lowContrast
            onCloseButtonClick={() => {}}
            className="mt-1 mb-1"
            title="Please select a valid start date and end date."
            subtitle=""
            hideCloseButton
            iconDescription="describes the close button"
            kind="error"
          />
        )}
        <div className="bx-row my-2">
          <div className="d-flex flex-column flex-lg-row">
            <div className="">
              <div className="bx--label mb-2">From:</div>
              <div className="">
                <DatePicker
                  selected={fromDate}
                  name="fromDate"
                  id="fromDate"
                  onChange={(date) => this.onDatePickerChange(date, 'fromDate')}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start Date"
                  className="css-h-d-input"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ml-lg-2 mt-2 mt-lg-0">
              <div className="bx--label mb-2">To:</div>
              <div className="">
                <DatePicker
                  selected={toDate}
                  name="toDate"
                  id="toDate"
                  onChange={(date) => this.onDatePickerChange(date, 'toDate')}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                  className="css-h-d-input"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ml-lg-2 mt-2 mt-lg-4 pt-2">
              <Button
                onClick={this.handleSubmitDates}
                className="mr-2"
                size="small">
                Submit
              </Button>
            </div>
          </div>
        </div>
        <GenericDataTable
          tbodyClass="candidate-background-color"
          placeHolderText="Search"
          searchable
          otherActions={otherActionButtons}
          onSearchInputChange={this.handleSearchSubmit}
          headers={headers}
          rows={
            assessments && Array.isArray(assessments) && assessments.length > 0
              ? assessments.map((assessment, idx) => {
                  const {
                    surveyId,
                    name,
                    selectionYear,
                    status,
                    startDate,
                    endDate,
                  } = assessment || {};

                  return {
                    isExpanded: false,
                    id: `row_${idx}`,
                    header_0: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Name:
                        </div>

                        <div className="xpa-link f-w-b h6 mb-0 text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          <a
                            className="css-cand-name"
                            href={`/app/csc/peer-feedback/assessment/${surveyId}`}>
                            {name}
                          </a>
                        </div>
                      </div>
                    ),
                    header_1: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Selection Year:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {selectionYear}
                        </div>
                      </div>
                    ),
                    header_2: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Status:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {status}
                        </div>
                      </div>
                    ),
                    header_3: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Start Date:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {startDate
                            ? moment(startDate).local().format('DD/MM/YYYY')
                            : '-'}
                        </div>
                      </div>
                    ),
                    header_4: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          End Date:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {endDate
                            ? moment(endDate).local().format('DD/MM/YYYY')
                            : '-'}
                        </div>
                      </div>
                    ),
                    header_5: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Action:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          <div className="d-flex justify-content-lg-center">
                            <div>
                              <Button
                                onClick={() =>
                                  this.openAddAssessModal(assessment)
                                }
                                kind="tertiary"
                                size="small">
                                Edit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
        />
        {((assessments &&
          Array.isArray(assessments) &&
          assessments.length <= 0) ||
          !assessments) && (
          <NoContentBox message="There are no candidates available for your current selection" />
        )}
        {assessmentsCount > 0 && (
          <Pagination
            onChange={this.handlePaginationChange}
            pageSizes={[10, 20, 30, 40, 50]}
            page={activePage}
            totalItems={assessmentsCount}
          />
        )}

        {console.log('selection **', currentYear, selectionYear)}

        {isAddAssessModalOpen && (
          <Modal
            open={true}
            modalHeading={`${surveyId ? 'Edit' : 'Add'} Assessment`}
            modalLabel="Action"
            primaryButtonText={isSubmitting ? 'Submitting...' : 'Submit'}
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissAddAssessModal}
            onRequestSubmit={this.handleSubmitAddAssess}>
            <div className="p-3">
              <div className="bx--row">
                <TextInput
                  required
                  id="assessmentName"
                  name="assessmentName"
                  labelText={
                    <div>
                      Assessment Name <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder="Assessment name"
                  onChange={(ev) => {
                    this.handleChange(ev);
                  }}
                  value={assessmentName}
                  invalid={isAssessNameInvalid}
                  invalidText="Please enter valid Assessment Name"
                />
              </div>

              <div className="bx--row  mt-2 ">
                <div className="d-flex flex-column">
                  <div className="bx--label mb-2">Start Date</div>
                  <DatePicker
                    selected={startDate && new Date(startDate)}
                    name="startDate"
                    id="startDate"
                    onChange={(date) =>
                      this.onDatePickerChange(date, 'startDate')
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className="css-h-d-input"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="bx--row  mt-2">
                <div className="d-flex flex-column">
                  <div className="bx--label mb-2">End Date</div>
                  <div>
                    <DatePicker
                      selected={endDate && new Date(endDate)}
                      name="endDate"
                      id="endDate"
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'endDate')
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      className="css-h-d-input"
                      autoComplete="off"
                    />
                  </div>

                  {isDateRangeInvalid && (
                    <div className="xpa-error-msg mt-1">
                      Please select a valid date. Start date cannot be greater
                      than end date.
                    </div>
                  )}
                </div>
              </div>

              <div className=" bx--row mt-2">
                <div className="d-flex flex-column w-100">
                  <SelectionYearDropdown
                    currentYear={currentYear}
                    selectionYear={selectionYear || 0}
                    handleChange={this.handleChange}
                    mainClassName="mt-2 w-50"
                    titleClassName="xpa-width"
                    showAllSelectionYears
                  />

                  {isSelectionYearInvalid && (
                    <div className="xpa-error-msg mt-1">
                      Please select a valid selection year.
                    </div>
                  )}
                </div>
              </div>

              <div className=" bx--row mt-2 w-100">
                <Select
                  className="w-100"
                  labelText="Status"
                  placeHolderText="Status"
                  name="status"
                  id="status"
                  onChange={this.handleChange}
                  value={status}
                  invalid={isStatusInvalid}
                  invalidText="Please select a valid status.">
                  <SelectItem text="Select" key={0} disabled />
                  <SelectItem
                    text="Draft"
                    key="Draft"
                    id="Draft"
                    value="Draft"
                  />
                  <SelectItem
                    text="In Progress"
                    key="In Progress"
                    id="In Progress"
                    value="In Progress"
                  />
                  <SelectItem
                    text="Completed"
                    key="Completed"
                    id="Completed"
                    value="Completed"
                  />
                </Select>
              </div>
            </div>
          </Modal>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
  user: state.x0paDuser.user,
  assessments: state.PscPeerFeedback.assessments,
  assessmentsCount: state.PscPeerFeedback.assessmentsCount,
});

const mapDispatchToProps = {
  getAssessments,
  addAssessment,
  updateAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentsList);
