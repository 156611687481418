const NavItems = () => {
  const items = [
    {
      label: 'Dashboard',
      subItems: [],
      path: '/app/csc',
      desc: '',
      selectedPaths: ['/app/csc'],
      accessRoles: [1, 2, 3, 6, 7],
    },
    {
      label: 'Statistics',
      subItems: [],
      path: '/app/csc/batch/info',
      desc: '',
      selectedPaths: ['/app/csc/batch/info'],
      accessRoles: [1, 2, 3, 6, 7],
    },
    {
      label: 'Interviews',
      subItems: [],
      path: '/app/csc/panel/interviews/list',
      desc: '',
      selectedPaths: ['/app/csc/panel/interviews/list'],
      accessRoles: [1, 2, 3, 6, 7, 8, 9, 10],
    },
    {
      label: 'Candidates',
      subItems: [],
      path: '/app/csc/candidate/list/all',
      desc: '',
      selectedPaths: ['/app/csc/candidate/list/all'],
      accessRoles: [1, 2, 3, 6, 7],
    },
    {
      label: 'Search',
      subItems: [],
      path: '/app/csc/search/candidates/list',
      desc: '',
      selectedPaths: ['/app/csc/search/candidates/list'],
      accessRoles: [1, 2, 3, 6],
    },
    {
      label: 'Calendar',
      subItems: [],
      selectedPaths: ['/app/csc/calendar/interviews'],
      path: '/app/csc/calendar/interviews',
      desc: '',
      accessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
    },
    {
      label: 'Reports',
      subItems: [],
      selectedPaths: ['/app/csc/high/level/reports'],
      path: '/app/csc/high/level/reports',
      desc: '',
      accessRoles: [1, 3, 14],
    },
    {
      label: 'Video Presentation',
      subItems: [],
      selectedPaths: ['/app/csc/vi/assigned/candidates'],
      path: '/app/csc/vi/assigned/candidates',
      desc: '',
      accessRoles: [1],
    },
    {
      label: 'Emails',
      subItems: [],
      selectedPaths: ['/app/csc/candidate/reports/emails'],
      path: '/app/csc/candidate/reports/emails',
      desc: '',
      accessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
    },
    {
      label: 'Assessments',
      desc: 'Assessments',
      accessRoles: [1, 3, 6, 14],
      selectedPaths: [
        '/app/csc/assessments/candidates',
        '/app/csc/assessments/reports',
        '/app/csc/assessments/settings',
      ],
      subItems: [
        {
          subLabel: 'Candidates',
          subItemsPath: '/app/csc/assessments/candidates',
          subItemDesc: 'Candidates',
          subAccessRoles: [1, 3],
        },
        {
          subLabel: 'Reports',
          subItemsPath: '/app/csc/assessments/reports',
          subItemDesc: 'Reports',
          subAccessRoles: [1, 6, 14],
        },
        {
          subLabel: 'Settings',
          subItemsPath: '/app/csc/assessments/settings',
          subItemDesc: 'Assessment Settings',
          subAccessRoles: [1, 3],
        },
      ],
    },
    {
      label: 'Meetings',
      desc: 'Schedule meetings with ARS users',
      accessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
      selectedPaths: [
        '/app/csc/cms/meetings',
        '/app/csc/cms/reports',
        '/app/csc/cms/email-templates',
        '/app/csc/cms/settings',
      ],
      subItems: [
        {
          subLabel: 'Meetings',
          subItemsPath: '/app/csc/cms/meetings',
          subItemDesc: 'Meetings',
          subAccessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
        },
        {
          subLabel: 'Reports',
          subItemsPath: '/app/csc/cms/reports',
          subItemDesc: 'Reports',
          subAccessRoles: [1, 6, 7, 11, 12, 13],
        },
        // {
        //   subLabel: 'Email Templates',
        //   subItemsPath: '/app/csc/cms/email-templates',
        //   subItemDesc: 'Email Templates',
        //   subAccessRoles: [1, 2, 3, 6, 7, 8, 9, 10],
        // },
        // {
        //   subLabel: 'Settings',
        //   subItemsPath: '/app/csc/cms/settings',
        //   subItemDesc: 'Assessment Settings',
        //   subAccessRoles: [1, 2, 3, 6, 7, 8, 9, 10],
        // },
      ],
    },
    {
      label: 'Peer Feedback',
      desc: 'Peer Feedback',
      accessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
      selectedPaths: ['/app/csc/peer-feedback/assessments'],
      subItems: [
        {
          subLabel: 'Assessments',
          subItemsPath: '/app/csc/peer-feedback/assessments',
          subItemDesc: 'Assessments',
          subAccessRoles: [1, 2, 3, 6, 7, 11, 12, 13],
        },
      ],
    },
    {
      label: 'Admin',
      desc: 'Everything Admin. Setup users, roles and access management',
      accessRoles: [1, 2, 3, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      selectedPaths: [
        '/app/csc/admin/users',
        '/app/csc/admin/vi/panel/settings',
        '/app/csc/admin/profile',
      ],
      subItems: [
        {
          subLabel: 'Users',
          subItemsPath: '/app/csc/admin/users',
          subItemDesc: 'View individual user information',
          subAccessRoles: [1],
        },
        {
          subLabel: 'Video Interview Settings',
          subItemsPath: '/app/csc/admin/vi/panel/settings',
          subItemDesc: 'View video interview panel members settings',
          subAccessRoles: [1],
        },
        {
          subLabel: 'Profile',
          subItemsPath: '/app/csc/admin/profile',
          subItemDesc: 'View your profile details',
          subAccessRoles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        },
        {
          subLabel: 'Audit Logs',
          subItemsPath: '/app/csc/admin/audit-logs',
          subItemDesc: 'View Audit Logs',
          subAccessRoles: [1],
        },
        {
          subLabel: 'Reset Panel Password',
          subItemsPath: '/app/csc/admin/reset-panel-password',
          subItemDesc: 'Reset panel members password',
          subAccessRoles: [1, 6],
        },
        {
          subLabel: 'Feedback Templates',
          subItemsPath: '/app/csc/admin/feedback-templates',
          subItemDesc: 'Evaluate using feedback templates',
          subAccessRoles: [1, 3],
        },
        {
          subLabel: 'Yearly Data Export',
          subItemsPath: '/app/csc/admin/yearly-data-export',
          subItemDesc: 'Generates yearly applications data',
          subAccessRoles: [1],
        },
      ],
    },
  ];
  return items;
};

export default NavItems;
