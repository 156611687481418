import callApi from './callApi';
import { v2BaseUrl } from '../utils/config';
import {
  REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENTS,
  REQUEST_ADD_PSC_PEER_FEEDBACK_ASSESSMENT,
  REQUEST_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT,
  REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID,
  REQUEST_UPLOAD_CANDIDATES,
  REQUEST_SEND_INVITES,
  REQUEST_SUBMIT_RETAKE_DECISION,
  REQUEST_PSC_PEER_FEEDBACK_EMAILS,
  REQUEST_PSC_PEER_FEEDBACK_ASSESS_EXPORT,
  REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  REQUEST_REASSIGN_GROUP_ID,
} from './actionTypes';

const getAssessments = (data) => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    searchKey,
    startDate,
    endDate,
    selectionYear,
    status,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;

  let url = `${v2BaseUrl}/psc-peer-feedback-survey?_=${new Date().getTime()}`;
  url += `&limit=${itemsPerPage}`;
  url += `&offset=${skip}`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (status) url += `&status=[${status.map((itm) => `"${itm}"`)}]`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  if (selectionYear) url += `&selectionYear=${selectionYear}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENTS,
  };
  return callApi(params);
};

const addAssessment = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${v2BaseUrl}/psc-peer-feedback-survey`;
  const params = {
    dispatch,
    actionType: REQUEST_ADD_PSC_PEER_FEEDBACK_ASSESSMENT,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const updateAssessment = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${v2BaseUrl}/psc-peer-feedback-survey`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAssessmentById = (data) => (dispatch) => {
  const { activePage, itemsPerPage, searchKey, surveyId, status, groupId } =
    data || {};
  if (!surveyId) {
    return Promise.reject(new Error('Invalid Id.'));
  }

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;

  let url = `${v2BaseUrl}/psc-peer-feedback-survey/${surveyId}?_=${new Date().getTime()}`;
  url += `&limit=${itemsPerPage}`;
  url += `&offset=${skip}`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (status) url += `&status=[${status.map((itm) => `"${itm}"`)}]`;
  if (groupId) url += `&groupId=${groupId}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID,
  };
  return callApi(params);
};

const uploadCandidates = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/psc-peer-feedback-survey/import?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CANDIDATES,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

const sendInvites = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/psc-peer-feedback-survey/invites`;
  const params = {
    dispatch,
    actionType: REQUEST_SEND_INVITES,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const submitRetakeDecision = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/psc-peer-feedback-survey/retake/decision`;
  const params = {
    dispatch,
    actionType: REQUEST_SUBMIT_RETAKE_DECISION,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getPscPeerFeedbackAssessExport = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/psc-peer-feedback-survey/export`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_PSC_PEER_FEEDBACK_ASSESS_EXPORT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAsessmentInfoByUUID = (publicUuid) => (dispatch) => {
  if (!publicUuid) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/psc-peer-feedback-assessment/${publicUuid}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  };
  return callApi(params);
};

const reassignGroupId = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/psc-peer-feedback-survey/groups`;
  const params = {
    dispatch,
    actionType: REQUEST_REASSIGN_GROUP_ID,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getAssessments,
  addAssessment,
  updateAssessment,
  getAssessmentById,
  uploadCandidates,
  sendInvites,
  submitRetakeDecision,
  getPscPeerFeedbackAssessExport,
  getAsessmentInfoByUUID,
  reassignGroupId,
};
