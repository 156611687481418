import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackRow from '../../../components/common/BackRow';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import { Accordion, AccordionItem, TextArea } from 'carbon-components-react';
import { getAsessmentInfoByUUID } from '../../../actions/PscPeerFeedback';
import moment from 'moment';
import _ from 'lodash';
import { getStatusColor, lowerAndTrim } from '../../../utils/helpers';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './RatingSlider.css'; // For custom CSS
import './PscPeerFeedback.css';

const renderHtml = (htmlObject) => {
  return (
    <div
      className="html-render-content"
      dangerouslySetInnerHTML={{ __html: htmlObject }}
    />
  );
};

class IndvAssessCandInfoPage extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    {
      id: 'psc-peer-feedback-responses',
      name: 'PSC Peer Feedback Responses',
      href: '',
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      isVertical: window.innerWidth <= 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleGetCandidateResponses();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleGetCandidateResponses = () => {
    const { match: { params: { publicId } = {} } = {} } = this.props;
    this.props
      .getAsessmentInfoByUUID(publicId)
      .then((res) => {
        if (res && !res.error) {
          const { sections, candidateInfo } = res || {};
          this.setState({
            sections,
            candidateInfo,
          });

          if (!_.isEmpty(sections)) {
            Object.values(sections).forEach((section, idx) => {
              this.populateDataFromGetAPI(section, (idx + 1).toString());
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  populateDataFromGetAPI = (currentSection = {}, sectionKey) => {
    const { candidateInfo } = this.state;
    const { candidateId } = candidateInfo || {};

    const {
      questions = [],
      peerList = [],
      responses = [],
    } = currentSection || {};

    let currCandResponses = [];
    if (sectionKey === '1') {
      currCandResponses = _.flatMap(peerList, (peer) =>
        _.values(peer.responses)
      );
    } else if (sectionKey === '2') {
      currCandResponses = _.values(
        peerList?.find((itm) => itm?.candidateId == candidateId)?.responses
      );
    } else if (sectionKey === '3') {
      currCandResponses = responses;
    }

    const updateStateObj = {};

    if (sectionKey === '1' || sectionKey === '2' || sectionKey === '3') {
      if (
        currCandResponses &&
        Array.isArray(currCandResponses) &&
        currCandResponses.length > 0
      ) {
        currCandResponses.forEach((itm) => {
          if (sectionKey === '1' || sectionKey === '2') {
            updateStateObj[`rating-${itm?.questionId}`] = itm?.value;
          } else if (sectionKey === '3') {
            const peerCandObj = questions?.[0]?.value?.choices?.find(
              (cand) => cand?.candidateId === itm?.evaluatedCandidateId
            );
            updateStateObj[`peer-cand-name-${itm?.evaluatedCandidateId}`] = {
              label: peerCandObj?.fullName,
              value: peerCandObj?.candidateId,
            };

            updateStateObj[`comment-${itm?.evaluatedCandidateId}`] =
              itm?.comments;
          }
        });
      }
      if (sectionKey === '3')
        updateStateObj.sectionThreeRecords =
          questions?.[0]?.value?.choices || [];
    } else if (sectionKey === '4') {
      if (responses && Array.isArray(responses) && responses.length > 0) {
        const candidateObj = _.find(_.flatMap(questions?.[0]?.value?.choices), {
          candidateId: responses?.[0]?.evaluatedCandidateId,
        });
        updateStateObj.peerCand = {
          label: candidateObj?.fullName,
          value: candidateObj?.candidateId,
        };
        updateStateObj.comments = responses?.[0]?.comments;
      }
    }

    this.setState({ ...updateStateObj });
  };

  handleResize = () => {
    this.setState({ isVertical: window.innerWidth < 768 });
  };

  render() {
    const { sections, candidateInfo, isVertical, sectionThreeRecords } =
      this.state;
    return (
      <div>
        <BackRow paths={this.paths} />
        {_.isEmpty(sections) ? (
          <NoContentBox message="There are currently no responses recorded for this candidate. Please check back later!" />
        ) : (
          <Accordion className="css-gba-accr-z" align="start">
            {_.map(_.entries(sections), ([sectionKey, section], idx) => {
              const { sectionName, status, questions, peerList } =
                section || {};
              const statusColor = getStatusColor(lowerAndTrim(status));
              return (
                <AccordionItem
                  className="content-padding mt-2"
                  title={
                    <div className="bx--row align-items-center justify-content-between">
                      <div className="ml-2 h6 font-weight-bold">
                        {sectionName}
                      </div>
                      <div className="d-flex justify-content-center mr-3">
                        <strong
                          className={`text-capitalize bx--tag ${statusColor} xpa-name-overflow`}
                          title={status}>
                          {status}
                        </strong>
                      </div>
                    </div>
                  }>
                  <div>
                    {sectionKey === '1' || sectionKey === '2' ? (
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass=""
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header:
                                sectionKey === '1'
                                  ? 'Peer Rating'
                                  : 'Self Rating',
                            },
                            {
                              key: 'header_1',
                              header: 'Status',
                            },
                          ]}
                          rows={
                            peerList &&
                            Array.isArray(peerList) &&
                            peerList.length > 0
                              ? peerList.map((itm, idx) => {
                                  const { candidateId, fullName, status } =
                                    itm || {};

                                  const statusColor = getStatusColor(
                                    lowerAndTrim(status)
                                  );

                                  return {
                                    id: `row_${idx + 1}_${candidateId}`,
                                    header_0: (
                                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                          Peer Feedback
                                        </div>
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {fullName}
                                        </div>
                                      </div>
                                    ),
                                    header_1: (
                                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                          Status
                                        </div>
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          <strong
                                            className={`text-capitalize bx--tag ${statusColor} xpa-name-overflow`}
                                            title={status}>
                                            {status}
                                          </strong>
                                        </div>
                                      </div>
                                    ),
                                  };
                                })
                              : []
                          }
                          expandable
                          expandedRows={
                            peerList &&
                            Array.isArray(peerList) &&
                            peerList.length > 0
                              ? peerList.map((itm, idx) => {
                                  return (
                                    <>
                                      {(sectionKey === '1' ||
                                        sectionKey === '2') && (
                                        <>
                                          {_.map(
                                            questions,
                                            (value, key, index) => {
                                              const { sectionNo, questionId } =
                                                value || {};
                                              const {
                                                title,
                                                ratings = [],
                                                leftIndicators,
                                                rightIndicators,
                                              } = value?.value || {};
                                              return (
                                                <div className="mb-3 bx--tile">
                                                  <h5 className="mb-2">
                                                    {title}
                                                  </h5>
                                                  <div className="bx--row d-flex align-items-center">
                                                    {/* Left Indicators */}
                                                    <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12 left-bg-color d-flex flex-column justify-content-center align-items-center">
                                                      {renderHtml(
                                                        leftIndicators
                                                      )}
                                                    </div>

                                                    {/* Rating Slider (Centered) */}
                                                    <div
                                                      className={`bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12 d-flex flex-column justify-content-center align-items-center ${
                                                        isVertical ? 'my-3' : ''
                                                      }`}>
                                                      <Slider
                                                        disabled
                                                        min={1}
                                                        max={6}
                                                        value={
                                                          this.state[
                                                            `rating-${questionId}`
                                                          ]
                                                        }
                                                        vertical={isVertical}
                                                        onChange={(val) =>
                                                          this.handleChange(
                                                            `rating-${questionId}`,
                                                            val
                                                          )
                                                        }
                                                        marks={ratings.reduce(
                                                          (acc, value) => {
                                                            acc[value] =
                                                              value.toString();
                                                            return acc;
                                                          },
                                                          {}
                                                        )}
                                                        trackStyle={{
                                                          backgroundColor:
                                                            '#1890ff',
                                                        }} // Blue track
                                                        handleStyle={{
                                                          backgroundColor:
                                                            '#1890ff',
                                                          borderColor:
                                                            '#1890ff',
                                                        }} // Blue handle
                                                      />
                                                    </div>

                                                    {/* Right Indicators */}
                                                    <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12 d-flex flex-column justify-content-center align-items-center">
                                                      {renderHtml(
                                                        rightIndicators
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })
                              : []
                          }
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          batchActions={false}
                        />
                      </div>
                    ) : sectionKey === '3' ? (
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass=""
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Rank',
                            },
                            {
                              key: 'header_1',
                              header: 'Candidate Name',
                            },
                            {
                              key: 'header_2',
                              header: 'Comments',
                            },
                          ]}
                          rows={
                            sectionThreeRecords &&
                            Array.isArray(sectionThreeRecords) &&
                            sectionThreeRecords.length > 0
                              ? sectionThreeRecords.map((itm, idx) => {
                                  const { candidateId: candId } = itm || {};
                                  const candidateName =
                                    this.state[`peer-cand-name-${candId}`]
                                      ?.label;

                                  return {
                                    id: `row_${idx + 1}_${candId}`,
                                    header_0: (
                                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                          Rank
                                        </div>
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {idx + 1}
                                        </div>
                                      </div>
                                    ),
                                    header_1: (
                                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                          Candidate Name
                                        </div>
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {candidateName}
                                        </div>
                                      </div>
                                    ),
                                    header_2: (
                                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                          Comments
                                        </div>
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          <TextArea
                                            disabled
                                            className=""
                                            key={`comment-${candId}`}
                                            name={`comment-${candId}`}
                                            labelText=""
                                            hideLabel
                                            onChange={() => {}}
                                            placeholder="Type Here"
                                            id={`comment-${candId}`}
                                            value={
                                              this.state[`comment-${candId}`]
                                            }
                                            rows={2}
                                          />
                                        </div>
                                      </div>
                                    ),
                                  };
                                })
                              : []
                          }
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          batchActions={false}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="">
                          <div className="d-flex align-items-center">
                            <div className="bx--type-zeta">
                              Candidate Name:{' '}
                            </div>
                            <div className="ml-3">
                              {this.state.peerCand?.label || ''}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 d-flex">
                          <div className="bx--type-zeta mb-2">Comments: </div>
                          <div className="ml-3 w-100">
                            <TextArea
                              disabled
                              className=""
                              key="comments"
                              name="comments"
                              labelText=""
                              hideLabel
                              onChange={() => {}}
                              placeholder="Type Here"
                              id="comments"
                              value={this.state.comments}
                              rows={6}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionItem>
              );
            })}
          </Accordion>
        )}

        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndvAssessCandInfoPage);
