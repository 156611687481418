import callApi from './callApi';
import { v2BaseUrl } from '../utils/config';
import {
  REQUEST_GET_ASSESSMENT_SCENARIOS,
  REQUEST_UPDATE_ASSESSMENT_SCENARIOS,
  REQUEST_GET_ASSESSMENT_CANDIDATES,
  REQUEST_ADD_ASSESSMENT_CANDIDATE,
  REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
  REQUEST_ALL_COUNTRIES,
  REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA,
  REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
  REQUEST_GET_ASSESS_COMPANY_TYPES,
  REQUEST_GET_ASSESS_COMPANIES,
  REQUEST_CREATE_ASSESS_COMPANY,
  REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS,
  REQUEST_UPLOAD_SCHOOLS_LIST,
  REQUEST_GET_ASSESS_REPORT,
  REQUEST_GET_ASSESS_REPORT_STATUS,
  REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO,
  REQUEST_UPLOAD_EMAIL_DOMAINS,
  REQUEST_GET_EMAIL_DOMAINS,
  REQUEST_EXPORT_ASSESSMENT_CANDIDATES,
  REQUEST_UPLOAD_CAND_NRIC,
  REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY,
  REQUEST_UPLOAD_TEST_BOOKING_FILE,
  REQUEST_EXPORT_CAND_TEST_BOOKING,
  REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT,
} from './actionTypes';

const getAsessmentScenarios = () => (dispatch) => {
  const url = `${v2BaseUrl}/scenario?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESSMENT_SCENARIOS,
  };
  return callApi(params);
};

const updatetAsessmentScenarios = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${v2BaseUrl}/scenario`;
  const params = {
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    actionType: REQUEST_UPDATE_ASSESSMENT_SCENARIOS,
    body: JSON.stringify(data),
    dispatch,
  };
  return callApi(params);
};

const getAsessmentCandidates = (data) => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    searchKey,
    startDate,
    endDate,
    scenarioId,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/candidate/list?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  if (startDate) {
    url += `&filter[startDate]=${startDate}`;
  }
  if (endDate) {
    url += `&filter[endDate]=${endDate}`;
  }
  if (scenarioId) {
    url += `&scenarioId=${scenarioId}`;
  }
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_GET_ASSESSMENT_CANDIDATES,
  };
  return callApi(params);
};

const exportAsessmentCandidates = (data) => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    searchKey,
    startDate,
    endDate,
    scenarioId,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/candidate/export?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  if (startDate) {
    url += `&filter[startDate]=${startDate}`;
  }
  if (endDate) {
    url += `&filter[endDate]=${endDate}`;
  }
  if (scenarioId) {
    url += `&scenarioId=${scenarioId}`;
  }

  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_EXPORT_ASSESSMENT_CANDIDATES,
  };
  return callApi(params);
};

const getAsessCandReportStatusByUuid = (exportUuid) => (dispatch) => {
  if (!exportUuid) {
    return Promise.reject(new Error('Invalid Export Id'));
  }
  let url = `${v2BaseUrl}/commonHelper/pollingStatus/candidates-data-export`;
  if (exportUuid) {
    url += `/${exportUuid}`;
  }
  url += `?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_ASSESS_CAND_REPORT_STATUS',
  };
  return callApi(params);
};

const getAsessmentCandidateInfo =
  ({ candidateId, emailId, publicUuid }) =>
  (dispatch) => {
    const url = `${v2BaseUrl}/candidate/single`;

    const data = {};
    if (candidateId) data['candidateIds'] = [candidateId];
    if (emailId) data['emails'] = [emailId];
    if (publicUuid) data['publicUuid'] = publicUuid;

    const params = {
      dispatch,
      method: 'POST',
      url,
      body: JSON.stringify(data),
      actionType: REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
    };
    return callApi(params);
  };

const addAsessmentCandidate = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${v2BaseUrl}/candidate`;
  const params = {
    dispatch,
    actionType: REQUEST_ADD_ASSESSMENT_CANDIDATE,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const updateAsessmentCandidate = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${v2BaseUrl}/candidate`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAllCountries = () => (dispatch) => {
  const url = `${v2BaseUrl}/Countries`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ALL_COUNTRIES,
  };

  return callApi(params);
};

const uploadAssessCandidatesData = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/candidate/fileUpload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const getAsessCompanyTypes =
  ({ ctypeIds }) =>
  (dispatch) => {
    let url = `${v2BaseUrl}/company2/types?_=${new Date().getTime()}`;
    if (ctypeIds && Array.isArray(ctypeIds) && ctypeIds.length > 0) {
      url += `&ctypeIds=[${ctypeIds}]`;
    }
    const params = {
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_GET_ASSESS_COMPANY_TYPES,
    };
    return callApi(params);
  };

const getAsessCompaniesById = (data) => (dispatch) => {
  const { activePage, itemsPerPage, searchKey, ctypeId } = data || {};
  if (!ctypeId) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/company2?_=${new Date().getTime()}`;
  if (ctypeId) {
    url += `&ctypeId=${ctypeId}`;
  }
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESS_COMPANIES,
  };
  return callApi(params);
};

const createAssessCompany = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/company2?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
    dispatch,
    actionType: REQUEST_CREATE_ASSESS_COMPANY,
    url,
    body: JSON.stringify(data),
    method: 'POST',
  };
  return callApi(params);
};

const updateCandAssessLinkSatus = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/assessment`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const uploadSchoolsList = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/company2/fileUpload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_SCHOOLS_LIST,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadEmailDomainsList = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/settings/3rdPartyDomains?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_EMAIL_DOMAINS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const getUploadedEmailDomains = () => (dispatch) => {
  const url = `${v2BaseUrl}/settings/3rdPartyDomains?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_EMAIL_DOMAINS,
  };
  return callApi(params);
};

const getAssessmentsReportInfo = (data) => (dispatch) => {
  const {
    activePage,
    itemsPerPage,
    searchKey,
    nricList,
    selectionYear,
    scenarioId,
    emailDomain,
    companyId,
    inviteDate,
    sendEmailTo,
    neoTestStatus,
    gbaTestStatus,
    numericalReasoningStatus,
    criticalThinkingStatus,
    exportData,
    neoResult,
    gbaResult,
    numericalReasoningResult,
    criticalThinkingResult,
    sendEmail,
    startDate,
    endDate,
  } = data || {};

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/candidate/export-am?_=${new Date().getTime()}`;
  url += `limit=${itemsPerPage}`;
  url += `skip=${skip}`;

  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }

  if (companyId) {
    url += `&companyId=${companyId}`;
  }

  if (selectionYear) {
    url += `&selectionYear=${selectionYear}`;
  }

  if (scenarioId) {
    url += `&scenarioId=${scenarioId}`;
  }

  if (startDate) {
    url += `&startDate=${startDate}`;
  }

  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  if (emailDomain) {
    url += `&emailDomain=${emailDomain}`;
  }

  if (inviteDate) {
    url += `&inviteDate=${inviteDate}`;
  }

  if (nricList && Array.isArray(nricList) && nricList.length > 0) {
    url += `&nricList=[${nricList.map((itm) => `"${itm}"`)}]`;
  }

  if (sendEmailTo && Array.isArray(sendEmailTo) && sendEmailTo.length > 0) {
    url += `&sendEmailTo=[${sendEmailTo.map((itm) => `"${itm}"`)}]`;
  }

  if (neoTestStatus) {
    url += `&neoTestStatus=["${neoTestStatus}"]`;
  }

  if (gbaTestStatus) {
    url += `&gbaTestStatus=["${gbaTestStatus}"]`;
  }

  if (numericalReasoningStatus) {
    url += `&numericalReasoningStatus=["${numericalReasoningStatus}"]`;
  }

  if (criticalThinkingStatus) {
    url += `&criticalThinkingStatus=["${criticalThinkingStatus}"]`;
  }

  if (neoResult) {
    url += `&neoResult=["${neoResult}"]`;
  }

  if (gbaResult) {
    url += `&gbaResult=["${gbaResult}"]`;
  }

  if (numericalReasoningResult) {
    url += `&numericalReasoningResult=["${numericalReasoningResult}"]`;
  }

  if (criticalThinkingResult) {
    url += `&criticalThinkingResult=["${criticalThinkingResult}"]`;
  }

  if (exportData) {
    url += `&exportData=${exportData}`;
  }

  if (sendEmail) {
    url += `&sendEmail=${sendEmail}`;
  }

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESS_REPORT,
  };
  return callApi(params);
};

const getAsessReportStatusById = (exportUuid) => (dispatch) => {
  if (!exportUuid) {
    return Promise.reject(new Error('Invalid Export Id'));
  }
  let url = `${v2BaseUrl}/candidate/export-am/check?_=${new Date().getTime()}`;
  if (exportUuid) {
    url += `&exportUuid=${exportUuid}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_ASSESS_REPORT_STATUS',
  };
  return callApi(params);
};

const getAsessReportDropdownInfo = () => (dispatch) => {
  let url = `${v2BaseUrl}/candidate/export-am/formFields?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO,
  };
  return callApi(params);
};

const uploadCandNrics = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/thirdPartyStatusChecker/upload-nrics?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CAND_NRIC,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

const getCandAsessStatusThirdParty = (data) => (dispatch) => {
  const { isExport } = data || {};
  let url = `${v2BaseUrl}/thirdPartyStatusChecker/check-status`;
  if (isExport) {
    url += `?isExport=${isExport}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY,
  };
  return callApi(params);
};

const getCandAsessStatusReportByUuid = (exportUuid) => (dispatch) => {
  if (!exportUuid) {
    return Promise.reject(new Error('Invalid Export Id'));
  }
  let url = `${v2BaseUrl}/commonHelper/pollingStatus/3rd-party-data-exports`;
  if (exportUuid) {
    url += `/${exportUuid}`;
  }
  url += `?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_CAND_ASSESS_STATUS_BY_UUID',
  };
  return callApi(params);
};

const uploadTestBookingfile = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/testbooking/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_TEST_BOOKING_FILE,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

const exportCandBookingStatus = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/testbooking/export?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EXPORT_CAND_TEST_BOOKING,
  };
  return callApi(params);
};

const uploadTestBookingUsageReport = () => (dispatch) => {
  const url = `${v2BaseUrl}/testbooking/usage-report?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT,
    url,
    method: 'POST',
    // body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getAsessmentScenarios,
  updatetAsessmentScenarios,
  getAsessmentCandidates,
  addAsessmentCandidate,
  updateAsessmentCandidate,
  getAllCountries,
  uploadAssessCandidatesData,
  getAsessmentCandidateInfo,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  updateCandAssessLinkSatus,
  uploadSchoolsList,
  getAssessmentsReportInfo,
  getAsessReportStatusById,
  getAsessReportDropdownInfo,
  uploadEmailDomainsList,
  getUploadedEmailDomains,
  exportAsessmentCandidates,
  getAsessCandReportStatusByUuid,
  uploadCandNrics,
  getCandAsessStatusThirdParty,
  getCandAsessStatusReportByUuid,
  uploadTestBookingfile,
  exportCandBookingStatus,
  uploadTestBookingUsageReport,
};
