export const lowerAndTrim = (ele) => {
  return typeof ele === 'string' ? ele?.toLowerCase()?.trim() : null;
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      const finalBase64 =
        fileReader?.result?.replace(/^data:.+;base64,/, '') || null;
      resolve(finalBase64);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isInValid = (val, type) => {
  const regExp = /^[a-zA-Z ]{2,30}$/;
  if (!val) {
    return true;
  }
  const valt = val.trim();
  if (!valt) {
    return true;
  }
  if (regExp.test(val) !== true && type !== 'email') {
    return true;
  }
  // if (type === 'email') {
  //   return !isEmail(valt);
  // }
  return false;
};

export const getStatusColor = (status) => {
  const statusColor =
    {
      'in progress': 'bx--tag--yellow',
      requested: 'bx--tag--yellow',
      rejected: 'bx--tag--red',
      approved: 'bx--tag--cyan',
      completed: 'bx--tag--green',
      'awaiting response': 'bx--tag--cyan',
    }[status] || 'bx--tag--gray';
  return statusColor;
};
