import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FileUploaderButton,
  Modal,
  Pagination,
  Select,
  SelectItem,
  Tile,
  ToggleSmall,
} from 'carbon-components-react';
import GenericTabs from '../../../components/common/GenericTabs';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  getAssessmentById,
  uploadCandidates,
  updateAssessment,
  sendInvites,
  submitRetakeDecision,
  getPscPeerFeedbackAssessExport,
  reassignGroupId,
} from '../../../actions/PscPeerFeedback';
import '../CandidateInfo.css';
import { getCurrEnvironment } from '../../common/common';
import moment from 'moment';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';
import BackRow from '../../../components/common/BackRow';
import PSC_Peer_Feedback_Template_Sample from '../../../assets/files/PSC_Peer_Feedback_Template_Sample.xlsx';
import { arrMap } from '../../../utils/reactMapUtils';
import { getStatusColor, lowerAndTrim } from '../../../utils/helpers';

const headers = [
  {
    key: 'header_0',
    header: 'Id',
  },
  {
    key: 'header_1',
    header: 'Candidate Name',
  },
  {
    key: 'header_2',
    header: 'Group',
  },
  {
    key: 'header_3',
    header: 'Overall Status',
  },
  {
    key: 'header_4',
    header: 'Retake Status',
  },
  {
    key: 'header_5',
    header: 'Actions',
  },
];

class AssessmentInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedTestResult: 0,
      isOpenInvitationModal: false,
      isBulkSelectionInvalid: false,
      bulkSelectedIds: [],
      activePage: 1,
      itemsPerPage: 10,
      searchKey: '',
      statusFilter: 'Status',
      groupIdFilter: 'Group Id',
    };
  }

  componentDidMount() {
    this.handleGetAssessInfo();
  }

  handleGetAssessInfo = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};

    const { activePage, itemsPerPage, searchKey, statusFilter, groupIdFilter } =
      this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      surveyId,
      status:
        !statusFilter || statusFilter == 'Status' || statusFilter === 'All'
          ? null
          : [statusFilter],
      groupId:
        !groupIdFilter || groupIdFilter == 'Group Id' || groupIdFilter === 'All'
          ? null
          : groupIdFilter,
    };
    this.props.getAssessmentById(data);
  };

  handleUpdateCandInfo = (data) => {
    const { match } = this.props;
    const { params } = match || {};
    const { candId } = params || {};
    this.setState({ isSubmitting: true }, () => {
      this.props
        .updateAsessmentCandidate([data])
        .then((res) => {
          const { isSuccess, error } = (res && res[candId]) || {};
          const { message } = error || {};
          this.dismissEditCandModal();
          if (isSuccess) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Candidate Info has been updated successfully',
                isSubmitting: false,
              },
              () => {
                this.handleGetAssessInfo();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
              errMsg: message,
              isSubmitting: false,
            });
          }
        })
        .catch((e) => {
          this.props.dismissAddCandModal();
          this.setState({
            errorNotif: true,
            isSubmitting: false,
          });
        });
    });
  };

  handleUploadCandidates = (element) => {
    const { uploadCandidates, match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    currFile.append('surveyId', surveyId);

    if (isFileAllowed) {
      uploadCandidates(currFile)
        .then((res) => {
          const { error, message } = res || {};
          if (res && !error && message) {
            this.setState({
              successNotif: true,
              errorNotif: false,
              notifMsg: message,
            });
          } else {
            this.setState({
              successNotif: false,
              errorNotif: true,
              errMsg:
                error?.message ||
                message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          successNotif: false,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUpdateAssessStatus = (status) => {
    const { match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};
    const data = { surveyId, status };
    this.props
      .updateAssessment(data)
      .then((res) => {
        const { error, message } = res || {};
        if (res && !error && message) {
          this.setState({
            successNotif: true,
            errorNotif: false,
            notifMsg: message,
          });
          this.handleGetAssessInfo();
        } else {
          this.setState({
            successNotif: false,
            errorNotif: true,
            errMsg:
              error?.message ||
              message ||
              'An Error has ocurred while processing your request, Please try again later',
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  handleSendInvitation = (type, pscPeerFeedbackAssessmentIds) => {
    const data = {
      type,
      pscPeerFeedbackAssessmentIds,
    };
    this.props
      .sendInvites(data)
      .then((res) => {
        const { error, message, responses } = res || {};
        this.dismissInvitationModal();
        if (res && !error && message) {
          this.setState({
            successNotif: true,
            errorNotif: false,
            notifMsg: message,
          });
          this.handleGetAssessInfo();
        } else {
          this.setState({
            successNotif: false,
            errorNotif: true,
            errMsg:
              error?.message ||
              'An Error has ocurred while processing your request, Please try again later',
          });
        }
      })
      .catch((e) => {
        this.dismissInvitationModal();
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({
      searchKey: event.target.value,
      activePage: 1,
      itemsPerPage: 10,
    });
    this.searchTimer = setTimeout(() => {
      this.handleGetAssessInfo();
    }, 1000);
  };

  batchActionClickHandler = (actionItems, actionState) => {
    const actionItemsIds = actionItems.map(({ id = '' }) => id);
    this.setState(
      { currActionState: actionState, bulkSelectedIds: actionItemsIds },
      () => {
        if (actionState === 'invite' || actionState === 'reminder') {
          this.setState({
            isOpenInvitationModal: true,
          });
        } else if (actionState === 'reassign-group-id') {
          this.setState({
            isOpenReassignGroupIdModal: true,
          });
        }
      }
    );
  };

  dismissInvitationModal = () => {
    this.setState({
      currActionState: null,
      isOpenInvitationModal: false,
      isBulkSelectionInvalid: false,
    });
  };

  dismissReassignGroupIdModal = () => {
    this.setState({
      isOpenReassignGroupIdModal: false,
      selectedGroupId: null,
    });
  };

  handleSubmitInvitationModal = () => {
    const { currActionState, bulkSelectedIds = [] } = this.state;
    const { assessmentInfo } = this.props;
    const { list } = assessmentInfo || {};
    let filteredList = [];
    if (arrMap(list).length > 0) {
      if (currActionState === 'invite') {
        filteredList = list
          .filter(
            (itm) =>
              !itm?.inviteSent &&
              arrMap(bulkSelectedIds).includes(itm?.pscPeerFeedbackAssessmentId)
          )
          .map((itm) => itm?.pscPeerFeedbackAssessmentId);
      } else if (currActionState === 'reminder') {
        filteredList = list
          .filter(
            (itm) =>
              itm?.inviteSent &&
              lowerAndTrim(itm?.status) !== 'completed' &&
              arrMap(bulkSelectedIds).includes(itm?.pscPeerFeedbackAssessmentId)
          )
          .map((itm) => itm?.pscPeerFeedbackAssessmentId);
      }
    }

    if (arrMap(filteredList).length > 0)
      this.handleSendInvitation(currActionState, filteredList);
    else this.setState({ isBulkSelectionInvalid: true });
  };

  handleRetakeDecision = (publicId, decision) => {
    const data = {
      publicId,
      decision,
    };
    this.props
      .submitRetakeDecision(data)
      .then((res) => {
        const { error, message } = res || {};
        if (res && !error && message) {
          this.setState({
            successNotif: true,
            errorNotif: false,
            notifMsg: message,
          });
          this.handleGetAssessInfo();
        } else {
          this.setState({
            successNotif: false,
            errorNotif: true,
            errMsg:
              error?.message ||
              'An Error has ocurred while processing your request, Please try again later',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetAssessInfo();
    });
  };

  handleExport = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};
    const data = { surveyId };
    this.setState({ isExporting: true }, () => {
      this.props
        .getPscPeerFeedbackAssessExport(data)
        .then((res) => {
          const { error } = res || {};
          if (res && !res.error) {
            const { fileUrl } = res || {};
            if (fileUrl) {
              window.open(fileUrl);
              this.setState({ isExporting: false });
            }
          } else {
            this.setState({
              isExporting: false,
              errorNotif: true,
              errMsg:
                error?.message ||
                'An error has ocurred while processing your request. Please try again later.',
            });
          }
        })
        .catch((error) => {
          console.error('error', error);
          this.setState({
            isExporting: false,
            errorNotif: true,
          });
        });
    });
  };

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  };

  handleSubmitReassignGroupIdModal = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};
    const { bulkSelectedIds = [], selectedGroupId } = this.state;

    const isSelectedGroupIdInvalid = !selectedGroupId;
    this.setState({ isSelectedGroupIdInvalid });
    if (isSelectedGroupIdInvalid) return;

    const list =
      bulkSelectedIds &&
      Array.isArray(bulkSelectedIds) &&
      bulkSelectedIds.map((itm) => {
        return {
          pscPeerFeedbackAssessmentId: itm,
          groupId: selectedGroupId,
        };
      });

    this.props
      .reassignGroupId({ surveyId, list })
      .then((res) => {
        const { error, message } = res || {};
        this.dismissReassignGroupIdModal();
        if (res && !error && message) {
          this.setState({
            successNotif: true,
            errorNotif: false,
            notifMsg: message,
          });
          this.handleGetAssessInfo();
        } else {
          this.setState({
            successNotif: false,
            errorNotif: true,
            errMsg:
              error?.message ||
              'An Error has ocurred while processing your request, Please try again later',
          });
        }
      })
      .catch((e) => {
        this.dismissReassignGroupIdModal();
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  handleFilterDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleGetAssessInfo();
      }
    );
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      isSubmitting,
      activePage,
      isOpenInvitationModal,
      isOpenReassignGroupIdModal,
      currActionState,
      isBulkSelectionInvalid,
      isExporting,
      isSelectedGroupIdInvalid,
      selectedGroupId,
      statusFilter,
      groupIdFilter,
    } = this.state;
    const { assessmentInfo, match } = this.props;
    const { params } = match || {};
    const { surveyId } = params || {};
    const { surveyInfo, list, count } = assessmentInfo || {};
    const {
      name,
      description,
      selectionYear,
      status,
      startDate,
      endDate,
      groupIdList,
    } = surveyInfo || {};

    const finalStatus = lowerAndTrim(status);

    const paths = [
      { id: 'csc', name: 'ARS', href: '/app/csc' },
      {
        id: 'psc-peer-feedback',
        name: 'Assessments',
        href: '/app/csc/peer-feedback/assessments',
      },
      { id: 'assessment-info', name: `${name}`, href: '' },
    ];

    const batchActions = [
      { label: 'Send Invite', key: 'invite' },
      { label: 'Send Reminder', key: 'reminder' },
    ];

    if (finalStatus === 'draft')
      batchActions.push({
        label: 'Reassign Group Id',
        key: 'reassign-group-id',
      });

    const otherActionButtons = [
      <div className="mr-3">
        <Select
          placeHolderText="Status"
          name="statusFilter"
          id="statusFilter"
          onChange={this.handleFilterDropDown}
          value={statusFilter}
          hideLabel
          className="xpa-width w-100 ">
          <SelectItem text="Status" key="Status" value="Status" disabled hidden/>
          <SelectItem text="Select" disabled />
          <SelectItem text="Draft" key="Draft" id="Draft" value="Draft" />
          <SelectItem
            text="Awaiting Response"
            key="Awaiting Response"
            id="Awaiting Response"
            value="Awaiting Response"
          />
          <SelectItem
            text="In Progress"
            key="In Progress"
            id="In Progress"
            value="In Progress"
          />
          <SelectItem
            text="Completed"
            key="Completed"
            id="Completed"
            value="Completed"
          />
          <SelectItem text="All" key="All" id="All" value="All" />
        </Select>
      </div>,

      <div className="mr-3">
        <Select
          placeHolderText="Group Id"
          name="groupIdFilter"
          id="groupIdFilter"
          onChange={this.handleFilterDropDown}
          value={groupIdFilter}
          hideLabel
          className="xpa-width w-100">
          <SelectItem
            text="Group Id"
            key="Group Id"
            value="Group Id"
            disabled
            hidden
          />
          <SelectItem text="Select" disabled />
          {groupIdList &&
            Array.isArray(groupIdList) &&
            groupIdList.length > 0 &&
            groupIdList.map((itm, idx) => {
              return <SelectItem text={itm} key={itm} id={itm} value={itm} />;
            })}

          <SelectItem text="All" key="All" id="All" value="All" />
        </Select>
      </div>,
    ];

    return (
      <div>
        {/* <BackRow paths={paths} /> */}

        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        <Tile>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className="h5 font-weight-bold text-capitalize text-uppercase">
                {name}
              </div>
              <div className="d-flex mt-2">
                <div className="css-default-text-color f-w-b">
                  Selection Year:
                </div>
                <div> {selectionYear}</div>
              </div>
            </div>
            <div className="d-flex flex-column mr-4">
              <div className="d-flex">
                <div className="css-default-text-color f-w-b">Start Date:</div>
                <div>
                  {' '}
                  {startDate
                    ? moment(startDate).local().format('DD/MM/YYYY')
                    : '-'}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="css-default-text-color f-w-b">End Date:</div>
                <div>
                  {' '}
                  {endDate ? moment(endDate).local().format('DD/MM/YYYY') : '-'}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="css-default-text-color f-w-b">Status:</div>
                <div> {status}</div>
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <div className="d-flex">
              {finalStatus === 'completed' ? (
                <div className="mr-3">
                  <Button
                    className=""
                    kind="primary"
                    onClick={() => this.handleUpdateAssessStatus('Inprogress')}>
                    Reopen Assessment
                  </Button>
                </div>
              ) : (
                <div className="mr-3">
                  <Button
                    className=""
                    kind="danger"
                    onClick={() => this.handleUpdateAssessStatus('Completed')}>
                    Close Assessment
                  </Button>
                </div>
              )}

              <div className="d-flex">
                <div className="">
                  <FileUploaderButton
                    buttonLabel="Import Candidates"
                    labelText="Import Candidates"
                    buttonKind="primary"
                    accept={['.xlsx']}
                    disableLabelChanges
                    name="file"
                    onChange={(e) => this.handleUploadCandidates(e)}
                  />
                  <div className="small mt-2">.xlsx format is allowed</div>
                </div>
                <div className="ml-2">
                  <Button
                    className="txt-dec-none"
                    kind="primary"
                    href={PSC_Peer_Feedback_Template_Sample}>
                    Download Template
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Button
                kind="primary"
                onClick={() => this.handleExport()}
                disabled={isExporting || finalStatus === 'draft'}>
                {isExporting ? 'Exporting...' : 'Export'}
              </Button>
            </div>
          </div>
        </Tile>
        <div className="mt-3">
          <GenericDataTable
            tbodyClass="candidate-background-color"
            placeHolderText="Search"
            searchable
            selectable
            batchActions={batchActions}
            batchActionClick={this.batchActionClickHandler}
            onSearchInputChange={this.handleSearchSubmit}
            headers={headers}
            otherActions={otherActionButtons}
            rows={
              list && Array.isArray(list) && list.length > 0
                ? list.map((itm, idx) => {
                    const {
                      publicId,
                      pscPeerFeedbackAssessmentId,
                      groupId,
                      status,
                      inviteSent,
                      info,
                      retakeStatus,
                      retakeInfo,
                    } = itm || {};

                    const { candidateId, nric, fullName, email } = info || {};

                    const finalStatus = lowerAndTrim(status);
                    const finalRetakeStatus = lowerAndTrim(retakeStatus);

                    return {
                      isExpanded: false,
                      id: pscPeerFeedbackAssessmentId,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            ID:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {candidateId}
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Candidate Name:
                          </div>
                          <div className="xpa-link f-w-b h6 mb-0 text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <a
                              className="css-cand-name"
                              href={`/app/csc/peer-feedback/assessment/${surveyId}/${publicId}`}>
                              {fullName}
                            </a>
                          </div>
                        </div>
                      ),
                      header_2: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Group:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {groupId}
                          </div>
                        </div>
                      ),
                      header_3: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Overall Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <strong
                              className={`text-capitalize bx--tag ${getStatusColor(
                                finalStatus
                              )} xpa-name-overflow`}
                              title={status}>
                              {status}
                            </strong>
                          </div>
                        </div>
                      ),
                      header_4: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Retake Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {retakeStatus ? (
                              <strong
                                className={`text-capitalize bx--tag ${getStatusColor(
                                  finalRetakeStatus
                                )} xpa-name-overflow`}
                                title={retakeStatus}>
                                {retakeStatus}
                              </strong>
                            ) : (
                              '-'
                            )}
                          </div>
                        </div>
                      ),
                      header_5: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Action:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex">
                              <div>
                                <Button
                                  onClick={() =>
                                    this.handleSendInvitation('invite', [
                                      pscPeerFeedbackAssessmentId,
                                    ])
                                  }
                                  kind="primary"
                                  size="small"
                                  disabled={inviteSent}>
                                  Invite
                                </Button>
                              </div>
                              <div className="ml-3">
                                <Button
                                  onClick={() =>
                                    this.handleSendInvitation('reminder', [
                                      pscPeerFeedbackAssessmentId,
                                    ])
                                  }
                                  kind="primary"
                                  size="small"
                                  disabled={
                                    !inviteSent || finalStatus === 'completed'
                                  }>
                                  Send Reminder
                                </Button>
                              </div>
                              {finalRetakeStatus === 'requested' && (
                                <>
                                  <div className="ml-3">
                                    <Button
                                      onClick={() =>
                                        this.handleRetakeDecision(
                                          publicId,
                                          'approved'
                                        )
                                      }
                                      kind="primary"
                                      size="small">
                                      Approve Retake
                                    </Button>
                                  </div>
                                  <div className="ml-3">
                                    <Button
                                      onClick={() =>
                                        this.handleRetakeDecision(
                                          publicId,
                                          'rejected'
                                        )
                                      }
                                      kind="danger"
                                      size="small"
                                      disabled={!inviteSent}>
                                      Reject Retake
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
          />
          {((list && Array.isArray(list) && list.length <= 0) || !list) && (
            <NoContentBox message="There are no candidates available for your current selection" />
          )}
          {count > 0 && (
            <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={activePage}
              totalItems={count}
            />
          )}
        </div>

        {isOpenInvitationModal && (
          <Modal
            open={isOpenInvitationModal}
            modalHeading={
              currActionState === 'invite'
                ? 'Send Invite(s)'
                : 'Send Reminder(s)'
            }
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            onRequestClose={this.dismissInvitationModal}
            onRequestSubmit={this.handleSubmitInvitationModal}>
            {`Are you sure you want to send ${
              currActionState === 'invite' ? 'invitation(s)' : 'reminder(s)'
            } to the selected candidate(s)`}
            ?
            {isBulkSelectionInvalid && (
              <div className="xpa-error-msg mt-1">
                Please select valid candidates to send the{' '}
                {currActionState === 'invite' ? 'invitation(s)' : 'reminder(s)'}
              </div>
            )}
          </Modal>
        )}

        {isOpenReassignGroupIdModal && (
          <Modal
            open={isOpenReassignGroupIdModal}
            modalHeading={'Reassign Group Id'}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            onRequestClose={this.dismissReassignGroupIdModal}
            onRequestSubmit={this.handleSubmitReassignGroupIdModal}>
            <Select
              className="w-100"
              labelText="Group Id"
              placeHolderText="Group Id"
              name="selectedGroupId"
              id="selectedGroupId"
              onChange={this.handleChange}
              value={selectedGroupId}
              invalid={isSelectedGroupIdInvalid}
              invalidText="Please select a valid group id">
              <SelectItem text="Select" key={0} />
              {groupIdList &&
                Array.isArray(groupIdList) &&
                groupIdList.length > 0 &&
                groupIdList.map((itm, idx) => {
                  return (
                    <SelectItem text={itm} key={idx} id={itm} value={itm} />
                  );
                })}
            </Select>
          </Modal>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
  assessmentInfo: state.PscPeerFeedback.assessmentInfo,
});

const mapDispatchToProps = {
  getAssessmentById,
  uploadCandidates,
  updateAssessment,
  sendInvites,
  submitRetakeDecision,
  getPscPeerFeedbackAssessExport,
  reassignGroupId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentInfoPage);
