import {
  REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENTS,
  SUCCESS_GET_PSC_PEER_FEEDBACK_ASSESSMENTS,
  ERROR_GET_PSC_PEER_FEEDBACK_ASSESSMENTS,
  REQUEST_ADD_PSC_PEER_FEEDBACK_ASSESSMENT,
  SUCCESS_ADD_PSC_PEER_FEEDBACK_ASSESSMENT,
  ERROR_ADD_PSC_PEER_FEEDBACK_ASSESSMENT,
  REQUEST_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT,
  SUCCESS_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT,
  ERROR_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT,
  REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID,
  SUCCESS_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID,
  ERROR_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID,
  REQUEST_UPLOAD_CANDIDATES,
  SUCCESS_UPLOAD_CANDIDATES,
  ERROR_UPLOAD_CANDIDATES,
  REQUEST_SEND_INVITES,
  SUCCESS_SEND_INVITES,
  ERROR_SEND_INVITES,
  REQUEST_SUBMIT_RETAKE_DECISION,
  SUCCESS_SUBMIT_RETAKE_DECISION,
  ERROR_SUBMIT_RETAKE_DECISION,
  REQUEST_PSC_PEER_FEEDBACK_ASSESS_EXPORT,
  SUCCESS_PSC_PEER_FEEDBACK_ASSESS_EXPORT,
  ERROR_PSC_PEER_FEEDBACK_ASSESS_EXPORT,
  REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  REQUEST_REASSIGN_GROUP_ID,
  SUCCESS_REASSIGN_GROUP_ID,
  ERROR_REASSIGN_GROUP_ID,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  assessments: [],
  assessmentsCount: 0,
  assessmentInfo: {},
};

const PscPeerFeedback = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_PSC_PEER_FEEDBACK_ASSESSMENTS:
      return {
        ...state,
        loading: false,
        assessments: payload?.list || [],
        assessmentsCount: payload?.count,
      };
    case ERROR_GET_PSC_PEER_FEEDBACK_ASSESSMENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: false,
      };
    case ERROR_ADD_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: false,
      };
    case ERROR_UPDATE_PSC_PEER_FEEDBACK_ASSESSMENT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID:
      return {
        ...state,
        loading: false,
        assessmentInfo: payload || {},
        error: null,
      };
    case ERROR_GET_PSC_PEER_FEEDBACK_ASSESSMENT_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SEND_INVITES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SEND_INVITES:
      return {
        ...state,
        loading: false,
      };
    case ERROR_SEND_INVITES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_CANDIDATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_CANDIDATES:
      return {
        ...state,
        loading: false,
      };
    case ERROR_UPLOAD_CANDIDATES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_SUBMIT_RETAKE_DECISION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SUBMIT_RETAKE_DECISION:
      return {
        ...state,
        loading: false,
      };
    case ERROR_SUBMIT_RETAKE_DECISION:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_PSC_PEER_FEEDBACK_ASSESS_EXPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_PSC_PEER_FEEDBACK_ASSESS_EXPORT:
      return {
        ...state,
        loading: false,
      };
    case ERROR_PSC_PEER_FEEDBACK_ASSESS_EXPORT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: null,
        assessmentInfo: payload,
      };
    case ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_REASSIGN_GROUP_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_REASSIGN_GROUP_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_REASSIGN_GROUP_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default PscPeerFeedback;
